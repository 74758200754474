import { useEffect } from 'react'
import type { AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { addHoverEvent } from '@/utils/addHoverEvent'
import { googleTagManagerId } from '@/utils/gtm'
import GoogleTagManager from '@/components/GoogleTagManager'
//import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

function MyApp({ Component, pageProps, router }: AppProps) {
  useEffect(() => {
    // Processing when hovering to a tag
    addHoverEvent()
  }, [router.pathname])

  return (
    <>
      {googleTagManagerId && <GoogleTagManager googleTagManagerId={googleTagManagerId} />}
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  )
}

export default MyApp
